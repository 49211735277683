<div class="container" style="background-color: #fff; width: 100%; z-index: 999;">
  <div class="help-line">
    <h4 (click)="onClickIsDEV()">Версия для слабовидящих</h4>
    <h4 (click)="onClickIsDEV()">Версия для глухонемых</h4>
  </div>
  <div class="fixed-size">
    <header>
      <img class="logo" src="/assets/images/logo-min.png" (click)="router.navigate(['/'])">
      <div class="auth-side" *ngIf="_service.isAuth">
        <button (click)="onClickIsDEV()">Связаться с помощником</button>
        <h3>Валеев Артём Р.</h3>
      </div>
      <button *ngIf="!_service.isAuth" (click)="onClickIsDEV()">Авторизация</button>
    </header>
  </div>
</div>
