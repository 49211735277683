<div class="footer-section">
  <div class="fixed-size">
    <div class="footer-section__container">
      <div class="footer-section__container-column">
        <h1 class="column-title">Помощь</h1>
        <h3 class="column-item">Как зарегистрироваться?</h3>
        <h3 class="column-item">Личный кабинет</h3>
        <h3 class="column-item">Часто задаваемые вопросы</h3>
      </div>
      <div class="footer-section__container-column">
        <h1 class="column-title">Просто для всех</h1>
        <h3 class="column-item">Гражданам</h3>
        <h3 class="column-item">Компаниям</h3>
        <h3 class="column-item">Волонтёрам</h3>
        <h3 class="column-item">Хочу быть соц. работником</h3>
      </div>
      <div class="footer-section__container-column">
        <h1 class="column-title">Безопасность</h1>
        <h3 class="column-item">Компании участники</h3>
        <h3 class="column-item">Сертифицированные волонтёры</h3>
        <h3 class="column-item">Личный курьер</h3>
      </div>
      <div class="footer-section__container-column">
        <h1 class="column-title"><span>Всё просто</span> в соцсетях</h1>
        <div class="socials-container">
        <a href="https://vk.com/eco_vseprosto" target="_blank"><div class="social vk"></div></a>
          <a href="https://t.me/eco_vseprosto" target="_blank"><div class="social tg"></div></a>
          <a href="https://www.youtube.com/channel/UCXqTOpGto20hdI5kET_ggvw" target="_blank"><div class="social yt"></div></a>
          <a href="https://vk.com/eco_vseprosto" target="_blank"><div class="social fb"></div></a>
        </div>
      </div>
    </div>
  </div>
</div>
